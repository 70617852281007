<template>
  <div class="re_wrap">
    <div class="title">
      <img src="@/assets/icon/DL_0005_fanhui.png" @click="handleClose" />
      <p class="versions">Đăng ký</p>
    </div>
    <div class="cont">
      <div
        class="form"
        v-for="(item, index) in formList"
        :key="index"
        :style="{
          'justify-content': item.key === 'phone' ? '' : 'space-between',
        }"
      >
        <span
          v-if="item.key === 'phone' && verConfig.areaCode"
          style="
            padding-left: 15px;
            padding-right: 12px;
            border-right: 1px solid #999;
          "
          >+{{ verConfig.areaCode }}</span
        >
        <!-- <van-dropdown-menu  v-if="item.key === 'phone'">
          <van-dropdown-item  v-model="value1" :options="option1" />
        </van-dropdown-menu> -->

        <input
          :type="item.type"
          :placeholder="item.label"
          :maxlength="item.length"
          v-model="form[item.key]"
        />
        <!-- <button v-if="item.key === 'code' && isSendCodeClick" @click="sendCode">
          gửi mã
        </button> -->
        <!-- <img
          class="code-img"
          :src="codeImg"
          v-if="item.key === 'code' && !isSendCodeClick"
          @click="sendCode"
        /> -->
        <button v-if="item.key === 'code' && !isSendCodeClick">
          {{ auth_time }}
        </button>
        <img
          v-if="
            (item.key === 'password' || item.key === 'againPassword') &&
            item.flag === true
          "
          src="@/assets/icon/Lark20220424-175950.png"
          @click="handleLookPass(item)"
        />
        <img
          v-if="
            (item.key === 'password' || item.key === 'againPassword') &&
            item.flag === false
          "
          src="@/assets/icon/Lark20220424-180000.png"
          @click="handleLookPass(item)"
        />
      </div>
      <!-- <p class="hintReference">Hóa đơn</p> -->
      <div class="btn-box">
        <button @click="handleConfirm">Nộp</button>
        <button @click="handleClose">Đã có tài khoản, đăng nhập ngay</button>
      </div>
    </div>
    <LoadingRE :show="LoadingShow" />
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  ActionSheet,
  Button,
  Toast,
  Field,
} from "vant";
// import NavBar from "@/components/NavBar.vue";

// 设置vant的多语言
import md5 from "js-md5";
import { Locale } from "vant";
import enUS from "vant/lib/locale/lang/en-US";
import Modelu from "@/utils/Module";
import antiShake from "@/utils/anti-shake";
import { getNewPhone } from "@/utils/tools";
import { sendSms, login } from "@/api/use.js";

// import { SEND_SMS_CODE, LOGIN } from "@/api";
import LoadingRE from "@/components/LoadingRE";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    LoadingRE,
  },
  data() {
    return {
      LoadingShow: false,
      form: {
        phone: "",
        userName: "",
        code: "",
        password: "",
        againPassword: "",
        referrer: "",
      },
      option1: [
        { text: "+1 USA", value: 0 },
        { text: "+1 Canada", value: 1 },
        { text: "+33 France", value: 3 },
        { text: "+39 Italy", value: 4 },
        { text: "+49 Germany", value: 5 },
        { text: "+52 Mexico", value: 6 },
        { text: "+54 Argentina", value: 7 },
        { text: "+55 Brazil", value: 8 },
        { text: "+60 Malaysia", value: 9 },
        { text: "+61 Australian", value: 10 },
        { text: "+62 Indonesia", value: 11 },
        { text: "+66 Thailand", value: 12 },
        { text: "+81 Japan", value: 13 },
        { text: "+84 Viet Nam", value: 14 },
        { text: "+90 turkey", value: 15 },
        { text: "+91 India", value: 16 },
        { text: "+93 Afghanistan", value: 17 },
        { text: "+855 Cambodia", value: 18 },
      ],

      value1: 0,
      formList: [
        // {
        //   label: `Vui lòng nhập một khu vực`,
        //   type: 'text',
        //   key: 'Area',
        //   length: 5,
        // },
        {
          label: `*Nhập số điện thoại di động`,
          type: "number",
          key: "phone",
          flag: false,
          require: true,
          length: 99,
          serverKey: "userphone",
        },
        // {
        //   label: `*tên tài khoản`,
        //   type: "text",
        //   key: "username",
        //   flag: false,
        //   require: true,
        //   length: 99,
        // },
        // {
        //   label: `Nhập mã xác nhận`,
        //   type: "text",
        //   key: "code",
        //   length: 8,
        // },
        // {
        //   label: `tên nick`,
        //   type: 'text',
        //   key: 'userName',
        //   length: 99,
        // },
        {
          label: `*Mật khẩu`,
          type: "password",
          key: "password",
          flag: false,
          require: true,
          length: 99,
          serverKey: "userpass",
        },
        {
          label: `*Xác nhận mật khẩu`,
          type: "password",
          key: "againPassword",
          flag: false,
          require: true,
          length: 99,
          serverKey: false,
        },
        // {
        //   label: `Mã lời mời (ID)`,
        //   type: "text",
        //   key: "referrer",
        //   flag: false,
        //   require: true,
        //   length: 99,
        // },
        // {
        //   label: "*mật khẩu thanh toán",
        //   type: "password",
        //   key: "paypass",
        //   flag: true,
        //   require: true,
        //   length: 99,
        // },
      ],
      isSendCodeClick: true,
      auth_time: 0,
      codeImg: "",
      showflag1: false,
      showflag2: false,
    };
  },
  created() {
    this.setFromId();
    try {
      const url = this.$route.fullPath.split("?")[1];
      const kv = url.split("&");
      const list = [];
      for (var i = 0; i < kv.length; i++) {
        const mode = {};
        const value = kv[i].split("=")[1];
        mode.key = value;
        list.push(mode);
      }
      list.forEach((_) => {
        if (_.key) {
          this.form.referrer = _.key;
        }
      });
    } catch (error) {}
  },
  methods: {
    /** handle **/
    sendCode: antiShake.debounce(function (e) {
      if (!this.form.phone.length) {
        Toast("Số điện thoại di động / số tài khoản không được để trống"); // 手机号/账号不能为空
        return false;
      }
      let form = {
        userphone: this.form.phone,
        must: 1, // this.type === 'reg' ? 1 : 2
      };
      /**
       * TO DO
       * 这个是短信验证码的，现在暂时不用了
       */
      // if (this.form.Area) {
      //   form.phone = `+${this.form.Area}-${this.form.phone}`
      // } else {
      //   form.phone = `${this.form.phone}`
      // }

      this.LoadingShow = true;
      SEND_SMS_CODE(form)
        .then((r) => {
          this.LoadingShow = false;
          if (r.data.ret === 1) {
            // Toast("Thành công")
            Toast(r.data.msg);
            this.isSendCodeClick = false;
            this.auth_time = 60;
            let auth_timetimer = setInterval(() => {
              this.auth_time--;
              if (this.auth_time <= 0) {
                this.isSendCodeClick = true;
                clearInterval(auth_timetimer);
              }
            }, 1000);
            // this.codeImg = `data:image/jpg;base64,${r.data.notifyList[0].data.code}`
          } else {
            Toast(r.data.msg);
          }
        })
        .catch((e) => {
          this.LoadingShow = false;
          ;
        });
    }, 200),
    handleClose() {
      this.form = {
        uesrName: "",
        code: "",
        password: "",
        againPassword: "",
      };
      // this.$router.go(-1)
      this.$router.push("/login");
    },
    async handleConfirm(e) {
      // let list = [
      //   "phone",
      //   "code",
      //   "userName",
      //   "password",
      //   "againPassword",
      //   "referrer",
      //   "paypass",
      //   "username",
      // ];
      // let list = ['phone', 'userName', 'password', 'againPassword']
      let count = 0;
      const requireNum = this.formList.filter((item) => item.require).length;
      // list.forEach((_) => {
      //   if (this.form[_]) {
      //     count++;
      //   }
      // });
      for (const key in this.form) {
        if (Object.hasOwnProperty.call(this.form, key)) {
          const value = this.form[key];
          if (!!value) {
            count++;
          }
        }
      }
      if (
        this.form.password !== this.form.againPassword &&
        this.formList.find((item) => item.key === "againPassword")
      ) {
        Toast("Mật khẩu không có"); // 密码不一致
      } else if (count < requireNum) {
        Toast("Không hoàn chỉnh"); // 输入内容不完整
      } else {
        this.register();
      }
    },
    handleLookPass(item) {
      this.formList.forEach((_) => {
        if (item.key === _.key) {
          if (_.type === "password") {
            _.type = "text";
            _.flag = true;
          } else {
            _.type = "password";
            _.flag = false;
          }
        }
      });
    },
    /** api **/
    async register() {
      const formData = {};
      for (let i = 0, len = this.formList.length; i < len; i++) {
        const item = this.formList[i];
        const { key, serverKey } = item;
        if (!!serverKey) {
          formData[serverKey] = this.form[key];
        }
      }
      if (this.formList.findIndex((item) => item.serverKey === "code") === -1) {
        formData.code = "112233";
      }
      const fromidLocal = localStorage.getItem("fromid");
      const fromidQuery = this.$route.query.id;
      if (fromidQuery || fromidLocal) {
        formData.fromid = fromidQuery ?? fromidLocal;
      }
      const { data } = await login({
        ...formData,
      });
      if (data.ret === 1) {
        this.$toast("Xin chúc mừng, đăng ký của bạn đã thành công.");
        // this.onlogin(formData);
        
        this.$store.commit("setUser", data.data.token);
        // this.$toast.success("đăng nhập thành công");
        localStorage.removeItem("fromid");
        this.$router.push("/");
      } else {
        this.$toast(data.msg);
      }
    },
    async onlogin(formData) {
      try {
        const {
          data: { ret, data },
        } = await login({
          userphone: this.user.mobile,
          userpass: this.user.password,
        });
        if (ret === 1) {
          this.$store.commit("setUser", data.token);
          this.$toast.success("đăng nhập thành công");
          localStorage.removeItem("fromid");
          this.$router.push("/");
        } else {
          this.$toast.fail(data.msg);
        }
      } catch (e) {}
    },
    /* 其他 */
    setFromId() {
      let fromid = this.$route.query.id || this.$route.query.fromid;
      if (fromid) {
        this.$cookie.set("fromid", fromid);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.re_wrap {
  min-height: 100vh;
  background: #fff;
  font-size: 16px * 2;
}
.title {
  height: 45px * 2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px * 2;
  position: relative;
  > img {
    width: 18px * 2;
    height: 18px * 2;
    position: absolute;
    top: 50%;
    left: 20px * 2;
    transform: translate(0, -50%);
    z-index: 9;
  }
}
.cont {
  // width: 295px * 2;
  /deep/ .van-dropdown-menu__bar {
    border-radius: 50vw;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    box-shadow: none;
  }
  width: 90%;
  margin: auto;
  box-shadow: 0 0.053333rem 0.533333rem 0.053333rem #dfdfdf;
  padding: 10px * 2;
  border-radius: 6px;
  margin-top: 10px * 2;
  > .form {
    width: 100%;
    height: 40px * 2;
    border-radius: 25px * 2;
    border: 1px * 2 solid #eee;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin-bottom: 15px * 2;
    > input {
      padding-left: 15px * 2;
      font-size: 14px * 2;
      border: 0;
      width: 80vw;
    }
    > button {
      // width: 77px * 2;
      height: 22px * 2;
      font-size: 12px * 2;
      color: #fff;
      background: red;
      border-radius: 5px;
      border: 0;
      margin-right: 15px * 2;
    }
    > img {
      width: 30px * 2;
      margin-right: 15px * 2;
    }
    > .code-img {
      width: 100px * 2;
      height: 40px * 2;
    }
  }
  > .hintReference {
    color: #ec0022;
    font-size: 14px * 2;
    margin: 0 10px * 2 15px * 2;
  }
  > .btn-box {
    display: flex;
    flex-direction: column;
    > button {
      width: 100%;
      height: 40px * 2;
      border-radius: 25px * 2;
      border: 0;
      font-size: 18px * 2;
    }
    > button:nth-child(1) {
      // background: #e23d6d;
      background: var(--theme);
      color: #fff;
      margin-bottom: 15px * 2;
    }
    > button:nth-last-child(1) {
      background: #fff;
      // color: #e23d6d;
      color: var(--theme);
      // border: 1px * 2 solid #e23d6d;
      border: 1px * 2 solid var(--theme);
    }
  }
}
</style>
