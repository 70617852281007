<template>
  <div class="my-container">
    <template v-if="!verConfig.useNewLogin">
      <nav-bar :title="$t('registe.registeredMember')" :backColor="'#ff0000'"></nav-bar>
      <div class="kong"></div>
      <div class="new-form">
        <div class="form-item" v-for="(item, index) in formList" :key="index">
          <span>
            <span v-if="item.required" style="color: #ff0000">* </span>{{ $t(item.label) }}
          </span>
          <!-- <input :type="item.type" :placeholder="$t(item.placeholder)" v-model="user[item.key]" /> -->
          <div class="inputs">
            <AreaPicker class="area" v-if="!index" @update:modelValue="modelValueChange" v-model="areaCode" />
            <input :type="item.type" :placeholder="$t(item.placeholder)" v-model="user[item.key]" />
          </div>
          <template v-if="item.key === 'code1'">
            <van-count-down :time="1000 * 60" :format="$t('registe.getBackSecond')" v-if="isCountDownShow"
              @finish="isCountDownShow = false" />
            <p v-else @click="onSendSms">{{ $t('registe.codeToast') }}</p>
          </template>
        </div>
        <div class="checkbox">
          <van-checkbox v-model="checked" shape="square" checked-color="#DC1323" icon-size="20px" />
          <p>
            {{ $t('registe.iAccept') }}<span @click="$router.push('agreement')">&lt;&lt;{{
      $t('registe.userAgreementAndPrivacyPolicy') }}&gt;&gt;</span>
          </p>
        </div>
        <div class="btn-box">
          <button @click="$router.back()">{{ $t('registe.backToLogin') }}</button>
          <button @click="onSubmit">{{ $t('registe.submitRegistration') }}</button>
        </div>
      </div>
    </template>
    <NewRegiste v-else />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import '../my.less'
import { sendSms, login } from '@/api/use.js'
import NewRegiste from '@/views/login/components/newRegiste.vue'
import AreaPicker from "@/components/areaPicker/index.vue";
import verConfig from "@/utils/ver"
export default {
  name: '',
  components: {
    'nav-bar': Navbar,
    NewRegiste,
    AreaPicker
  },
  data() {
    return {
      user: {
        mobile: '',
        code: '112233',
        password: '',
        fromid: '',
        confirmPwd: ''
      },
      checked: true,
      isCountDownShow: false,
      formList: [
        {
          label: 'login.phoneNumber',
          required: true,
          type: 'number',
          placeholder: 'login.pleaseEnterPhoneNumber',
          key: 'mobile'
        },
        // {
        //   label: 'mã mời',
        //   type: 'number',
        //   placeholder: 'Vui lòng nhập mã mời',
        //   key: 'code',
        // },
        {
          label: 'login.password',
          type: 'password',
          required: true,
          placeholder: 'login.pleaseEnterPassword',
          key: 'password'
        },
        {
          // this.$t('login.pleaseEnterPhoneNumber')
          label: 'login.confirmPassword',
          type: 'password',
          required: true,
          placeholder: 'login.pleaseReEnterPassword',
          key: 'confirmPwd'
        },
        {
          label: 'login.referralCode',
          type: 'text',
          required: false,
          placeholder: 'login.pleaseEnterReferralCode',
          key: 'fromid'
        }
      ],
      areaCode: verConfig.areaCode
    }
  },
  mounted() { },
  created() {
    if (this.$route.query.id) {
      localStorage.setItem('fromid', this.$route.query.id)
      this.user.fromid = this.$route.query.id
    }
  },
  methods: {
    modelValueChange(areaCode){
      this.areaCode = areaCode
    },
    async onSendSms() {
      if (!this.user.mobile) return this.$toast(this.$t('login.pleaseEnterPhoneNumberOne'));
      this.isCountDownShow = true
      try {
        const { data } = await sendSms({
          userphone: this.user.mobile,
          must: 1
        })
        data.ret === 1 ? this.$toast(data.msg) : this.$toast(data.msg)
      } catch (error) {
        this.isCountDownShow = false
      }
    },
    async onSubmit(e) {
      e.stopPropagation()
      if (!this.checked) return this.$toast(this.$t('registe.youMustAgree'));
      let flag = true
      for (let i = 0, len = this.formList.length; i < len; i++) {
        const item = this.formList[i]
        if (item.required) {
          if (!this.user[item.key]) {
            flag = false
            return this.$toast(item.msg || this.$t(item.placeholder))
            break
          }
        }
      }
      if (!!this.formList.filter((item) => item.key === 'confirmPwd').length &&
        this.user.confirmPwd !== this.user.password
      ) {
        return this.$toast(this.$t('registe.twoInconsistentPasswordEntries'))
      }
      const parame = {
        userphone: this.user.mobile,
        code: this.user.code,
        userpass: this.user.password,
        ke: true,
        fromid: this.user.fromid,
        country_code: this.areaCode
      }
      if (this.$route.query.id || localStorage.getItem('fromid')) {
        parame.fromid =
          this.$route.query.id || localStorage.getItem('fromid')
      }
      const { data } = await login({ ...parame });
      if (data.ret === 1) {
        this.$toast(data.msg)
        this.onlogin()
        localStorage.removeItem('fromid')
      } else {
        this.$toast(data.msg)
      }
    },

    // 3、注册成功直接登录
    async onlogin() {
      try {
        const {
          data: { ret, data, msg }
        } = await login({
          userphone: this.user.mobile,
          userpass: this.user.password,
          country_code: this.country_code
        })
        if (ret === 1) {
          this.$store.commit('setUser', data.token)
          this.$toast.success(msg)
          localStorage.removeItem('fromid')
          this.$router.push('/')
        } else {
          this.$toast.fail(msg)
        }
      } catch (e) { }
    }
  }
}
</script>

<style lang="less" scoped>
.goLogin {
  margin-top: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;

  span {
    font-size: 26px;
  }
}

.registe {
  .van-field {
    margin-top: 18px;
  }

  .send-sms-btn {
    width: 152px;
    height: 46px;
    line-height: 46px;
    background-color: #fff;
    font-size: 22px;
    color: rgb(0, 76, 254);
    border: none;
  }
}

.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 50px 0 18px 50px;

  .van-checkbox {
    margin-right: 25px;
  }

  >p {
    width: calc(100% - 20px);
    font-size: 30px;
    color: #b2b2b2;

    >span {
      color: #dc1323;
    }
  }
}

.registe-btn {
  background: #ff0000;
  border: none;
}

.registe-info {
  position: relative;
}

.otc-btn {
  position: absolute;
  top: 22px;
  right: 30px;
  background-color: #fff;
  padding-left: 10px;
  color: rgb(0, 76, 254);
  font-size: 35px;
  font-family: "PingFang-Medium";
}

.kong {
  height: 100px;
}

.new-form {
  width: 100%;
  height: calc(100% - 100px);
  background-color: #fff;

  .van-count-down {
    font-size: 30px;
  }

  >.form-item {
    width: 100%;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
    flex-direction: column;
    padding: 30px;
    box-sizing: border-box;
    position: relative;

    >span {
      font-size: 36px;
      color: #323233;
      margin-bottom: 15px;
    }
    .inputs{
      display: flex;
      align-items: center;
      .area{
        font-size: 30px;
        color: #555555;
        margin-right: 10px;
      }
    }
    input {
      font-size: 36px;
      color: #323233;
      border: 0;
    }

    p {
      font-size: 30px;
      color: #555555;
    }
  }

  >.form-item:nth-child(1) {
    padding-top: 50px;

    >input {
      padding-left: 70px;
    }

    >.num-absolute {
      position: absolute;
      font-size: 36px;
      color: #323233;
      bottom: 32px;
    }
  }

  >.btn-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    >button {
      width: 40%;
      height: 100px;
      border-radius: 6px;
      border: 0;
      font-size: 32px;
      color: #fff;
      background-color: #3eb4ff;
    }

    >button:nth-last-child(1) {
      background-color: #dc1323;
      margin-left: 50px;
    }
  }
}
</style>
